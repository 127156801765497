<template>
  <div class="absolute flex flex-col w-full h-full">
    <div class="sticky top-0 z-50 text-xs bg-gray-700 select-none tabs">
      <div
        class="tab"
        :class="{ 'tab-active' : active === 'scene' }"
        @click.prevent="active = 'scene'"
      >
        Scene
      </div>
      <div
        class="tab"
        :class="{ 'tab-active' : active === 'content' }"
        @click.prevent="active = 'content'"
      >
        Content
      </div>
      <div
        class="tab"
        :class="{ 'tab-active' : active === 'viewer' }"
        @click.prevent="active = 'viewer'"
      >
        Viewer
      </div>
    </div>

    <SceneTree
      v-show="active === 'scene'"
      :viewer="viewer"
      :active-node-id="activeNodeId"
    />

    <template
      v-if="contentTreeComponent"
    >
      <component
        :is="contentTreeComponent"
        v-show="active === 'content'"
      />
    </template>

    <ViewerBody
      v-show="active === 'viewer'"
      :gui="gui"
      @settings-changed="onSettingsChanged"
    />

    <ExtrasTree
      v-show="active === 'extras'"
      v-bind:viewer="viewer"/>
  </div>
</template>
<script>
const SceneTree = () => import('./SceneTree')
const ContentTree = () => import('./ContentTree')
const ContentTreeSandbox = () => import('./ContentTreeSandbox')
const ExtrasTree = () => import('./ExtrasTree')
const ViewerBody = () => import('../viewer/ViewerBody')

export default {
  components: {
    SceneTree,
    ContentTree,
    ContentTreeSandbox,
    ExtrasTree,
    ViewerBody
  },

  props: {
    viewer: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      default: 'editor'
    }
  },

  data () {
    return {
      active: 'extras',
      activeNodeId: null
    }
  },

  computed: {
    contentTreeComponent () {
      if (this.context === 'sandbox') return 'ContentTreeSandbox'
      return 'ContentTree'
    },
    gui () {
      return this.viewer.core.gui
    }
  },

  mounted () {
    this.attachBusListeners()
  },

  methods: {
    attachBusListeners () {
      this.$bus.$on('editor:inspector:clearactive', this.onClearActive)
      this.$bus.$on('editor:inspector:makeactive', this.onMakeActive)
    },

    onMakeActive (key) {
      this.activeNodeId = key
    },

    onClearActive () {
      this.activeNodeId = null
    },

    onSettingsChanged () {
      this.setDirty()
      this.$emit('settings-changed')
    },

    setDirty () {
      this.viewer.core.requestRenderIfNotRequested()
    }
  }

}
</script>
